import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"

const Sponsorship = () => {
  return (
    <Layout>
      <Metadata title="Sponsorship" description="Our sponsors and information about sponsoring CBCO Lido." />
      <div>
        <h1>Sponsorship</h1>
        <p>Lorem ipsum.</p>
      </div>
    </Layout>
  )
}

export default Sponsorship